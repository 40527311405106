import React, { useEffect, useState } from "react";
import "./arrow.css";

type Props = {
  direction: "up" | "down";
};

export default function Arrow({ direction }: Props) {
  const [isFadeOut, setIsFadeOut] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFadeOut(true);
    }, 3000);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);
  return (
    <button
      className={`arrow arrow-${direction} ${
        isFadeOut ? "arrow--fade-out" : ""
      }`}
    >
      ˇ
    </button>
  );
}
