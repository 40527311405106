import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Poster from "../components/poster";
import Bio from "../components/bio";
import Layout from "../components/layout";
import Arrow from "../components/arrow";
import getHashState from "../hashstate";

type Props = {
  data: any;
  location: any;
};

function Index({ data, location }: Props) {
  const posts = data.allMarkdownRemark.edges;
  const thumbnail = data.thumbnail.childImageSharp.fixed.src;
  const [hashState] = useState<string>(getHashState());
  const [hasAbove, setHasAbove] = useState(false);
  const [hasBelow, setHasBelow] = useState(false);

  useEffect(() => {
    if (!hashState) return;
    const posters = document.querySelectorAll(".poster");
    if (!posters) {
      console.error("Expected to find .poster(s)");
      return;
    }
    // if the first one doesn't match then there are more below
    const firstId = posters[0].id;
    if (firstId !== hashState) {
      setHasAbove(true);
    }
    const lastId = posters[posters.length - 1].id;

    // if the last one doesn't match then there are more above
    if (lastId !== hashState) {
      setHasBelow(true);
    }
  }, [setHasAbove, setHasBelow]);

  const [isFadedIndex, setIsFadedIndex] = useState(null);

  return (
    <Layout location={location}>
      <SEO title="" thumbnail={thumbnail} />
      {hasAbove && <Arrow direction="up" />}
      {hasBelow && <Arrow direction="down" />}
      {posts.map(({ node }, index) => {
        return (
          <Poster
            index={index}
            node={node}
            hasLink
            isFaded={isFadedIndex !== null ? isFadedIndex === index : null}
            setIsFaded={isFaded => {
              setIsFadedIndex(index);
            }}
          />
        );
      })}
      <Bio />
    </Layout>
  );
}

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    thumbnail: file(relativePath: { eq: "wanderer.jpg" }) {
      childImageSharp {
        fixed(width: 1024) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/projects/" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            poster {
              childImageSharp {
                fluid(maxWidth: 3840) {
                  ...GatsbyImageSharpFluid_tracedSVG
                  presentationWidth
                }
              }
            }
            gallery {
              childImageSharp {
                fluid(maxWidth: 3840) {
                  ...GatsbyImageSharpFluid_tracedSVG
                  presentationWidth
                }
              }
            }
          }
        }
      }
    }
  }
`;
